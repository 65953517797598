import { getInputType } from '../form/formFieldParser'
import {
    extractNumericalValue,
    replaceQuotesWithNulls,
    extractDateValue,
} from './numerical'
import { capitalizeText, decapitalizeText } from '../stringUtils'

const convertStringToBool = v => {
    if (v === 'true') {
        return true
    }
    if (v === 'false') {
        return false
    }
    return v
}

const convertFieldDuplicateString = (IsFieldDuplicate, fkColumnName ) => {
    if (IsFieldDuplicate && fkColumnName === 'uploadSampleId') {
        return ' - Duplicate'
    } else {
        return ''
    }
}

const issuesInNavgroup = (groupName, errorState, uploadConfig, level) => {
    const flattenedIssues = Object.keys(errorState).reduce((acc, curr) => {
        const dataAccessor = curr.split('[')[0]
        const errors = errorState[curr]
        if (acc[dataAccessor]) {
            return {
                ...acc,
                [dataAccessor]: [...acc[dataAccessor], ...errors]
            }
        } else {
            return {
                ...acc,
                [dataAccessor]: errors
            }
        }
    }, {})
    const fieldsAssociatedWithNavGroup = uploadConfig.filter(c => c.SectionGroupName === groupName)
    const navGroupHasIssues = Object.keys(flattenedIssues).map(dataAccessor => {
        var issues = flattenedIssues[dataAccessor]
        var hasIssues = issues.filter(x => x.severity === level).map(issue => fieldsAssociatedWithNavGroup.find(field => {
                if (issue.fieldName === "") {
                    return field.DataAccessor === dataAccessor
                } else {
                    return field.DataAccessor === dataAccessor && decapitalizeText(field.ColumnName) === issue.fieldName
                }
            })
        ).some(x => x)
        return hasIssues
    }).some(x => x)
    return navGroupHasIssues
}

const getValueFromDataRow = (fieldName, newRow, config) => {
    if (fieldName.includes('Select') && !fieldName.includes('DateSelect')) {
        const inputName = fieldName.replace('Select', '')
        const associatedConfig = config.find(x => decapitalizeText(x.ColumnName) === inputName)
        const values = associatedConfig.Values
        const value = newRow[inputName]
        const valueEntry = values.find(x => x.code.toString() === value.toString())
        return {
            active: associatedConfig.active,
            value: value,
            label: valueEntry.codedescription
        }
    } else {
        const inputName = fieldName.replace('Select', '').replace('DateSelect', '')
        return newRow[inputName]
    }
}


const errorsInNavgroup = (groupName, errorState, uploadConfig) => issuesInNavgroup(groupName, errorState, uploadConfig, 'Error')

const warningsInNavgroup = (groupName, errorState, uploadConfig) => issuesInNavgroup(groupName, errorState, uploadConfig, 'Warning')

const removeInputTypeNames = (o, uploadConfig) => {
    return Object.keys(o).reduce((acc, curr) => {
        const formEntry = o[curr]
        const isSelect = curr.includes('Select')
        const isDate = curr.includes('DateSelect')
        const isCheckBox = curr.includes('CheckBox')
        const strippedFieldName = 
            capitalizeText(
                curr
                    .replace('Input', '')
                    .replace('DateSelect', '')
                    .replace('Select', '')
                    .replace('CheckBox', '')
            )
        const associatedFieldConfig = uploadConfig.find(
            x => x.ColumnName === strippedFieldName
        )
        if (isCheckBox) {
            return {
                ...acc,
                [strippedFieldName]: formEntry
            }
        }
        if (isDate) {
            return {
                ...acc,
                [strippedFieldName]: extractDateValue(formEntry),
            }
        }
        if (isSelect && formEntry) {
            if (!associatedFieldConfig) {
                return {
                    ...acc,
                    [strippedFieldName]: extractNumericalValue(formEntry.value)
                }
            }
            return {
                ...acc,
                [strippedFieldName]:
                    getInputType(associatedFieldConfig.DataType) === 'number'
                        ? extractNumericalValue(formEntry.value)
                        : convertStringToBool(replaceQuotesWithNulls(formEntry.value)),
            }
        }
        return {
            ...acc,
            [strippedFieldName]:
                getInputType(associatedFieldConfig ? associatedFieldConfig.DataType : null) === 'number' &&
                formEntry
                    ? extractNumericalValue(formEntry)
                    : convertStringToBool(replaceQuotesWithNulls(formEntry)),
        }
    }, {})
}

const getValuesFromData = (data, uploadConfig) => {
    const newData = Object.keys(data).reduce((acc, groupName) => {
        const associatedData = data[groupName]
        if (Array.isArray(associatedData)) {
            return {
                ...acc,
                [groupName]: associatedData.map(d =>
                    removeInputTypeNames(d, uploadConfig)
                ),
            }
        } else {
            return {
                ...acc,
                [groupName]: removeInputTypeNames(associatedData, uploadConfig),
            }
        }
    }, {})
    return newData
}

const isChanged = (
    uploadChanges,
    dataAccessor,
    name,
    idx,
    submissionState
) => {
    if (uploadChanges && uploadChanges[dataAccessor]) {
        if (isNaN(idx)) {
            return !!uploadChanges[dataAccessor].some(x => x.column.toLowerCase() === name.toLowerCase())
        } else {
            if (submissionState && submissionState[dataAccessor] && submissionState[dataAccessor][idx]) {
                const uploadData = submissionState[dataAccessor][idx]
                const tableIdColumn = dataAccessor + 'Id'
                const uploadChangeIdColumn = dataAccessor.slice(0, 1).toUpperCase() + dataAccessor.slice(1) + 'ID'
                const id = uploadData[tableIdColumn]
                if (typeof id !== 'undefined') {
                    const delta = !!uploadChanges[dataAccessor].some(x => x.column.toLowerCase() === name.toLowerCase() && x[uploadChangeIdColumn] === id)
                    return delta
                }
            }
        }
    }
    return false
}

const getWarningFromErrorState = (errorState, name, rowIdx, dataAccessor) => {
    if (errorState) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(x => x.fieldName === name && x.severity === 'Warning')
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(x => x.severity === 'Warning' && x.fieldName === name)
                    return e ? e : null
                }
            }
            return null
        }
    }
}

const getApiErrorFromErrorState = (errorState, name, rowIdx, dataAccessor, formError) => {
    if (errorState && !formError) {
        if (isNaN(rowIdx) && errorState[dataAccessor]) {
            const e = errorState[dataAccessor].find(x => x.fieldName === name && x.severity === 'Error')
            return e
        } else {
            if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                if (errorState[`${dataAccessor}[${rowIdx}]`]) {
                    const e = errorState[`${dataAccessor}[${rowIdx}]`].find(x => x.severity === 'Error' && x.fieldName === name)
                    return e ? e : null
                }
            }
            return null
        }
    }
}

const getConfigRefCodeValue = (field, value, uploadConfig) => {
    const associatedConfig = uploadConfig.find(x => x.ColumnName === field)
    
    if (associatedConfig && associatedConfig.Values) {
        const associatedRefCode = associatedConfig.Values.find(x => x.code === value)
        if (associatedRefCode) {
            return associatedRefCode.codedescription
        }
    }
    return value
}
export { getConfigRefCodeValue, removeInputTypeNames, getValuesFromData, isChanged, getApiErrorFromErrorState, getWarningFromErrorState, warningsInNavgroup, errorsInNavgroup, convertFieldDuplicateString, convertStringToBool, getValueFromDataRow  }
