import React, { useContext } from 'react'

import Filter from './Filter'
import List from './List'
import Panel from '../panel/Panel'
import { AppStateContext } from '../../features/explorer/AppStateContext'
import DataContextProvider from './DataContextProvider'
import Header from './Header'

const Explorer = ({
    name,
    formName,
    DataContext,
    apiController,
    width,
    panelTitle,
    panelClassConfig,
    disclaimer
}) => {
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        promotedRecords,
        togglePromotedRecords,
        toggleCollapsed,
        collapsedExplorers,
        expandedMenu,
        setExpandedMenu,
    } = useContext(AppStateContext)
    const filterExpanded = filterState[formName]
    const toggleFilter = updateFilterState.bind(this, formName)
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider
                    name={name}
                    formName={formName}
                    apiController={apiController}
                    DataContext={DataContext}
                >
                    <Header
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords[name]}
                        togglePromotedRecords={togglePromotedRecords}
                        toggleCollapsed={toggleCollapsed}
                        isCollapsed={collapsedExplorers[name]}
                        extraClass={`${formName}Header`}
                        name={name}
                        formName={formName}
                        panelTitle={panelTitle}
                        isExpanded={expandedMenu === name}
                        setExpandedMenu={setExpandedMenu}
                    />
                    <Filter
                        width={width}
                        filterExpanded={filterExpanded}
                        isCollapsed={collapsedExplorers[name]}
                        syncFilterState={syncFilterState}
                        DataContext={DataContext}
                        disclaimer={disclaimer}
                    />
                    <List
                        isCollapsed={collapsedExplorers[name]}
                        promotedRecords={promotedRecords}
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        DataContext={DataContext}
                    />
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Explorer
