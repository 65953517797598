import React, { useContext, useEffect, useCallback, useMemo } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import merge from 'lodash.merge'

import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import { DataContext } from '../DataContext'
import Table from './TablePanel'
import Form from './FormPanel'
import SaveButton from './SaveButton'
import {
    getValuesFromData,
} from '../../../../utils/submissions/values'
import ResetButton from './ResetButton'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'
import { setReactiveValues } from '../../../../utils/form/reactiveValues'
import { decapitalizeText } from '../../../../utils/stringUtils'
import { getRequiredText, shouldDisplayRequiredText } from '../../../../utils/submissions/helperText'
import { populateConfigWithForeignKeyValues } from '../../../../utils/submissions/foreignKeys'

const getFormComponentType = type => {
    switch (type) {
        case 'Form':
            return Form
        case 'Table':
            return Table
        default:
            return null
    }
}

const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = decapitalizeText(config.ColumnName)
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const isNotNullOrUndefined = value => {
    return !(typeof value === 'undefined' || value === null)
}

const getSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value) ||
            (value === true && x.code === 'true') ||
            (value === false && x.code === 'false')
    )
    if (option) {
        return {
            label: option.codedescription,
            value: option.code,
            active: option.active,
        }
    } else {
        return value
    }
}

const getCreatableSelectValue = (values, initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : ''
    const option = values.find(
        x =>
            x.code === value.toString() ||
            x.code === value ||
            x.code === parseInt(value)
    )
    if (option) {
        return {
            label: option.codedescription,
            value: option.code,
            active: option.active,
        }
    } else {
        if (value !== '') {
            return { label: value, value: value, active: true }
        } else {
            return value
        }
    }
}

const getCheckBoxValue = (initialValue, defaultValue) => {
    const value = isNotNullOrUndefined(initialValue)
        ? initialValue
        : defaultValue
        ? defaultValue
        : 0
    return value
}

const getValueFromUploadConfig = (initialValue, config) => {
    const value = initialValue
    switch (config.ControlType) {
        case 'TextBox':
            return value
        case 'Select':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'MultiSelect':
            return getSelectValue(config.Values, value, config.DefaultValue)
        case 'DateDayPicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY')
                      .toString()
                : null
        case 'DatePicker':
            return value
                ? dayjs(value)
                      .format('MM/DD/YYYY hh:mm A')
                      .toString()
                : null
        case 'CheckBox':
            return getCheckBoxValue(value, config.DefaultValue)
        case 'Creatable':
            return getCreatableSelectValue(
                config.Values,
                value,
                config.DefaultValue
            )
        default:
            return value
    }
}

const transformDBValuesToFormValues = (
    x,
    sectionConfig,
) => {
    if (!x) {
        return {}
    }
    return Object.keys(x).reduce((acc, fieldName) => {
            let config = sectionConfig.find(
                c => decapitalizeText(c.ColumnName) === fieldName
            )
            let name = getInputNameFromConfig(config, fieldName)
            const dbValue = x[fieldName]
            const value = config
                ? getValueFromUploadConfig(dbValue, config)
                : dbValue
            return {
                ...acc,
                [name]: value,
            }
    }, {})
}


const getDefaultValuesFromSubmissionState = (
    panelName,
    initialUploadConfig,
    submissionState,
    printable=false
) => {
    // populate uploadConfig with foreign key values from other tables
    const uploadConfig = populateConfigWithForeignKeyValues(initialUploadConfig, submissionState)
    
    // populate table with config
    if (panelName || printable) {
        let relatedConfig = uploadConfig.filter(
            x => x.SectionGroupName === panelName
        )
        if (printable) {
            relatedConfig = uploadConfig
        }
        const subSections = [
            ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
        ]
        const defaultValues = subSections.reduce((acc, section) => {
            const sectionData = relatedConfig.filter(
                x => x.UploadSectionGroupName === section
            )
            const sd = sectionData.reduce((accc, row) => {
                if (Array.isArray(submissionState[row.DataAccessor])) {
                    const rows = submissionState[row.DataAccessor]
                    const values = rows.map(x => {
                        return transformDBValuesToFormValues(
                            x,
                            relatedConfig,
                        )
                    })
                    return {
                        [row.DataAccessor]: [...values],
                        ...accc,
                    }
                } else {
                    return {
                        [row.DataAccessor]: {
                            ...transformDBValuesToFormValues(
                                submissionState[row.DataAccessor],
                                relatedConfig
                            ),
                        },
                        ...accc,
                    }
                }
            }, {})
            return {
                ...acc,
                ...sd,
            }
        }, {})
        
        return defaultValues
    } else {
        return {}
    }
}

const FormComponent = ({ panelName, onSubmit, printable }) => {
    const {
        activePanel,
        uploadConfig,
        setFormMethods,
        setFormDirty,
        viewOnly,
        submissionState,
    } = useContext(DataContext)
    const defaultValues = useMemo(
        () =>
            getDefaultValuesFromSubmissionState(
                panelName,
                uploadConfig,
                submissionState,
                printable
            ),
        [submissionState, uploadConfig, panelName, printable]
    )
    const formMethods = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
    })
    const { formState: { dirty }, watch } = formMethods
    const formValues = watch()

    const currentPageData = useMemo(() => {
        if (panelName || printable) {
            let relatedConfig = uploadConfig.filter(
                x => x.SectionGroupName === panelName
            )

            if (printable) {
                relatedConfig = uploadConfig
            }
            const subSections = [
                ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
            ]
            return subSections.map((section, idx) => {
                const sectionData = relatedConfig.filter(
                    x => x.UploadSectionGroupName === section
                )
                const exampleRow = sectionData.length && sectionData[0]
                return {
                    config: sectionData,
                    type: exampleRow.SectionType,
                    accessor: exampleRow.DataAccessor,
                    title: exampleRow.UploadSectionGroupName,
                    subtitle: exampleRow.UploadSectionGroupDescription,
                    oneRowRequired: exampleRow.OneRowRequired,
                    printable,
                    key: `form-component-${idx}`,
                }
            })
        } else {
            return []
        }
    }, [panelName, uploadConfig, printable])

    const pageComponents = useMemo(() => {
        return currentPageData.map((d, idx) => {
            const componentType = getFormComponentType(d.type)
            const displayRequiredText = shouldDisplayRequiredText(d, idx)
            return React.createElement(componentType, {
                ...d,
                displayRequiredText,
                requiredText: getRequiredText(d)
            })
        })
    }, [currentPageData])

    useEffect(() => {
        if (activePanel === panelName) {
            setFormMethods(formMethods)
        }
    }, [activePanel])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [activePanel, defaultValues])

    useEffect(() => {
        setReactiveValues(watch({ nest: true }), formMethods.setValue)
    }, [formValues])

    return (
        (activePanel === panelName || printable) && (
            <div>
                <LoadingWrapper data={currentPageData}>
                    <FormContext {...formMethods}>
                        <form
                            onSubmit={formMethods.handleSubmit(onSubmit)}
                            noValidate
                            className="columns is-multiline explorerForm is-centered"
                        >
                            {pageComponents}
                            {!viewOnly && !printable && (
                                <div className="column is-4 buttonWrapper">
                                    <SaveButton />
                                    <ResetButton />
                                </div>
                            )}
                        </form>
                    </FormContext>
                </LoadingWrapper>
            </div>
        )
    )
}

export default () => {
    const {
        navGroups,
        uploadConfig,
        tableData,
        submissionState,
        submitForValidation,
        printableView: printable
    } = useContext(DataContext)
    const onSubmit = useCallback(data => {
        // perform update to empty array if we set
        // the 'reset' flag on the form (corrseponds to empty array)
        Object.keys(data).map(key => {
            const associatedData = data[key]
            if (associatedData === 'reset') {
                data[key] = []
            } else if (associatedData === 'pageEmpty') {
                data[key] = tableData[key] ? tableData[key] : []
            }
            return null
        })

        // get the values from the data and update the submission state
        // as a merge of the values w/ submission state
        const values = getValuesFromData(data, uploadConfig)
        const copiedData = cloneDeep(submissionState)
        Object.keys(values).map(key => {
                const associatedData = values[key]
                const previousData = copiedData[key]
                if (Array.isArray(associatedData)) {
                    const td = cloneDeep(tableData[key])
                    if (associatedData === 'reset') {
                        copiedData[key] = []
                    } else {
                        const test = td ? merge(td, associatedData) : associatedData
                        copiedData[key] = test ? test : []
                    }
                } else {
                    copiedData[key] = merge(previousData, associatedData)
                }
                return null
            })
        submitForValidation(copiedData)
    }, [tableData, submissionState, submitForValidation])

    return (
        <div>
            {!printable &&
                navGroups.map((group, idx) => {
                    const key = `form-component-${idx}`
                    return group.type === 'component' ? (
                        React.createElement(group.component, {
                            key: key,
                            printable: printable,
                            ...group.props,
                        })
                    ) : (
                        <FormComponent
                            key={key}
                            printable={printable}
                            panelName={group.groupName}
                            onSubmit={onSubmit}
                        />
                    )
                })}
            {printable && (
                <FormComponent printable={printable} panelName={null} />
            )}
        </div>
    )
}
