import React, { useContext } from 'react'

import { formatValue } from '../../../../../utils/table/parseColumns'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import { AppStateContext } from '../../AppStateContext'
import withConfig from '../../../../wrappers/withConfig'

const isNotNullOrUndefined = value => {
    return !(typeof value === 'undefined' || value === null)
}

const mapDataToLabels = props => {
    return Object.keys(props.formFields).reduce((acc, fieldName) => {
        const field = props.formFields[fieldName]
        const fieldLabel = field.Prompt
        const fieldColumn = field.ColumnName
        const fieldValue = props.data[fieldColumn]
        return {
            ...acc,
            [fieldColumn]: {
                fieldLabel,
                fieldValue,
            },
        }
    }, {})
}

const FieldComponent = ({ fieldLabel, fieldValue, size }) => {
    const textSize = size ? size : '7'

    return (
        <div className="field is-horizontal">
            <div className={`label is-size-${textSize}`}>{fieldLabel}</div>
            <div
                className={`value margin-left-sm is-size-${textSize}`}
                dangerouslySetInnerHTML={{
                    __html: isNotNullOrUndefined(fieldValue) ?  formatValue(fieldValue) : '', // WNS-198
                    // __html: isNotNullOrUndefined(fieldValue) ?  formatValue(fieldValue) : 'None',
                }}
            />
        </div>
    )
}

const WellLinkComponent = ({ fieldLabel, fieldValue, linkId }) => {
    const appContext = useContext(AppStateContext)
    if (!appContext) {
        return (
            <FieldComponent fieldLabel={fieldLabel} fieldValue={fieldValue} />
        )
    }
    const { updateDetailState } = appContext

    return (
        <div className="field is-horizontal">
            <div className="label is-size-7">{fieldLabel}</div>
            <div
                className="value margin-left-sm is-size-7 linkCell"
                onClick={() => {
                    updateDetailState('well', {
                        visible: true,
                        facilityID: linkId ? linkId : fieldValue,
                    })
                }}
            >
                {formatValue(fieldValue)}
            </div>
        </div>
    )
}

const SourceLinkComponent = ({ fieldLabel, fieldValue, linkId, config }) => {
    const appContext = useContext(AppStateContext)
    if (!appContext) {
        return (
            <FieldComponent fieldLabel={fieldLabel} fieldValue={fieldValue} />
        )
    }
    const { SOURCELINK_URL_BASE } = config

    return (
        <div className="field is-horizontal">
            <div className="label is-size-7">{fieldLabel}</div>
            <a
                className="value margin-left-sm is-size-7 linkCell"
                href={`${SOURCELINK_URL_BASE}/${linkId}`}
                target="_blank"
                style={{ cursor: 'pointer' }}
            >
                {/* {formatValue(linkId)} */}
                View
            </a>
        </div>
    )
}

export default withConfig (({ config, tooltipData, tooltipFilterData }) => {
    const { borderClass } = useContext(PanelStyleContext)

    const tooltipDetailProps = {
        formFields: tooltipFilterData,
        data: tooltipData,
        fieldGroups: [...new Set(tooltipFilterData.map(x => x.GroupName))],
    }

    const tooltipDetailData = mapDataToLabels(tooltipDetailProps)
    return (
        <div className={`tooltipDataWrapper ${borderClass}`}>
            <div className="columns is-multiline">
                <div className="column is-full">
                    <div className="label">Site Details</div>
                </div>
                <div className="column is-full">
                    <WellLinkComponent
                        {...tooltipDetailData['USGS_ID']}
                        linkId={tooltipData['FacilityID']}
                    />
                    {/* <FieldComponent
                        {...tooltipDetailData['USGS_ID']}
                    /> */}
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['FacilityName']} 
                    />
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['FacilityTypeDisplay']}
                    />
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['Agency']} 
                    />
                </div>
                <div className="column is-full">
                    <SourceLinkComponent
                        {...tooltipDetailData['SourceLink']}
                        linkId={tooltipData['USGS_ID']}
                        config={config} 
                    />
                </div>
                <div className="column is-full">
                    <FieldComponent
                        {...tooltipDetailData['WellDepth']} 
                    />
                </div>
                <div className="column is-full">
                    <div className="column is-full">
                        <div className="label">Water Level Overview</div>
                    </div>
                    <div className="column is-half">
                        <FieldComponent
                            {...tooltipDetailData['WaterLevelCount']}
                        />
                    </div>
                    <div className="column is-half">
                        <FieldComponent
                            {...tooltipDetailData['LastMeasurementDate']}
                        />
                    </div>
                    <div className="column is-half">
                        <FieldComponent
                            {...tooltipDetailData['HighestWaterLevel']}
                        />
                    </div>
                    <div className="column is-half">
                        <FieldComponent
                            {...tooltipDetailData['LowestWaterLevel']}
                        />
                    </div>
                </div>
                {/* <div className="column is-half">
                    <div className="column is-full">
                        <div className="label">
                            Sample Overview
                        </div>
                    </div>
                    <div className="column is-full">
                        <FieldComponent {...tooltipDetailData['FacilitySampleCount']} />
                    </div>
                    <div className="column is-full">
                        <FieldComponent {...tooltipDetailData['LastSampleDate']} />
                    </div>
                </div> */}
            </div>
        </div>
    )
})
