import React, { useContext, useState, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'

import { ParameterContext } from '../../wrappers/ParameterContext'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { UserContext } from '../../wrappers/UserContext'
import { getQueryStringFromParams } from '../../../utils/params'
import toast from '../../elem/Toast'
import withConfig from '../../wrappers/withConfig'
import { AppStateContext } from '../explorer/AppStateContext'
import {
    hasAccessToAdmin,
    hasAccessToPWS,
} from '../../../utils/user/permissions'
import Modal from '../../elem/Modal'

export default withConfig(({ config }) => {
    const [exporting, setExporting] = useState(false)
    const [displayDialogue, setDisplayDialogue] = useState(false)
    const [exportEmail, setExportEmail] = useState('')
    const [error, setError] = useState(null)
    const { params } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const {
        mapState: { mapSelectionIds },
    } = useContext(AppStateContext)
    const { user, roles } = useContext(UserContext)
    const { PROCESS_API_URL, DB_EXPORT_TITLE } = config

    const checkIfFiltersAreApplied = useCallback(() => {
        return new Promise((resolve, reject) => {
            const paramString = getQueryStringFromParams(params, true)
            if (
                paramString === '' &&
                (mapSelectionIds === '' || mapSelectionIds === null)
            ) {
                toast({
                    level: 'info',
                    message:
                        'There are no filters applied. To download the full database, click the Download Database button.',
                    alert: true,
                })
                return reject()
            }
            return resolve()
        })
    }, [params, mapSelectionIds])

    const exportFilterResults = useCallback(() => {
        setExporting(true)
        const paramString = getQueryStringFromParams(params, true)
        authenticatedFetch(`${PROCESS_API_URL}/export/request?${paramString}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify({
                exportRequestEmail: exportEmail,
                selectedIds: '',
                mapSelectionIds,
            }),
        })
            .then(async response => {
                if (response.ok) {
                    return null
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                // close dialogues and reset state
                setExporting(false)
                setDisplayDialogue(false)
                setExportEmail('')
                
                // make a toast for the user with level success
                toast({
                    level: 'info',
                    alert: true,
                    message:'Export Requested. You will receive an email when the export is ready for download.'                
                })
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Export Request Failed: ' +
                        (e.message
                            ? e.message
                            : 'Please check filter parameters and try again.'),
                })
            })
            .finally(() => {
                setExporting(false)
                setDisplayDialogue(false)
                setExportEmail('')
            })
    }, [
        user,
        exportEmail,
        params,
        mapSelectionIds,
        DB_EXPORT_TITLE,
        PROCESS_API_URL,
    ])

    const downloadDatabase = useCallback(() => {
        setExporting(true)
        authenticatedFetch(`${PROCESS_API_URL}/export/download`)
            .then(async response => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(data => {
                const dateString = dayjs(Date.now()).format('MM-DD-YYYY')
                saveAs(
                    data,
                    `${DB_EXPORT_TITLE}_Full_${dateString}.zip`
                )
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Download Failed: ' +
                        (e.message
                            ? e.message
                            : 'Contact the system administrator to ensure the file is present on the server.'),
                })
            })
            .finally(() => setExporting(false))
    }, [DB_EXPORT_TITLE, PROCESS_API_URL, roles])

    useEffect(() => {
        var re = new RegExp(/^\S+@\S+\.\S+$/) //regex to test email correctness
        if (!re.test(exportEmail) && exportEmail !== null) {
            setError('Please enter a valid email address')
        }
        else {
            setError(null)
        }
    }, [exportEmail])

    const onEmailChange = useCallback(event => {
        const email = event.target.value
        setExportEmail(email)
    }, [])

    return (
        <>
            <button
                className={`button is-info is-small`}
                onClick={() => checkIfFiltersAreApplied().then(() => setDisplayDialogue(true))}
                disabled={exporting}
            >
                Export Filter Results
                {/* { exporting ? "" : 'Export Filter Results'} */}
            </button>
            <button
                className="button is-info is-small"
                onClick={() => downloadDatabase()}
                disabled={exporting}
            >
                Download Database
            </button>
            <Modal
                display={displayDialogue}
                pending={exporting}
                disabledCondition={error === null}
                confirmAction={() => exportFilterResults()}
                denyAction={() => setDisplayDialogue(false)}
                confirmText="Request Export"
                denyText="Cancel"
                title={`Request Export`}
            >
                <p>
                    This will request an export of the current filter results. 
                    You will be notified by email when the export is ready for 
                    download.
                </p>
                <p>
                    <strong>NOTE:</strong> The download link will expire after 24 hours.
                </p>
                <br />
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Email Address:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control is-expanded">
                                <input
                                    type="email"
                                    className="input"
                                    value={exportEmail}
                                    disabled={exporting}
                                    placeholder="Email Address"
                                    onChange={onEmailChange}
                                />
                                <div className="help is-danger inputErrors">
                                    {error && exportEmail !== null && (
                                        <span className="inputErrorText">
                                            {error}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
})
