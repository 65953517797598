import React, { useContext, useEffect } from 'react'

import Form from '../form/Form'
import FormResultsSummary from '../form/FormResultsSummary'
import getFilterParamsFromForm from '../../../utils/form/getFilterParamsFromForm'
import clearFormFields from '../../../utils/form/clearFormFields'

const Filter = ({ 
        DataContext, 
        filterExpanded, 
        syncFilterState,
        isCollapsed,
        width,
        disclaimer
    }) => {
    const dataContext = useContext(DataContext)
    const {
        filterFields,
        fetchFilterFields,
        fetchResultsCount,
        filterResultsCount,
        filterResultsLoading,
        params,
        setParams,
        formName        
    } = dataContext

    const onSubmit = (data, e) => {
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params[formName].SortByField,
            SortOrder: params[formName].SortOrder,
            ...getFilterParamsFromForm(data),
        }
        setParams(newParams, formName)
        syncFilterState(false)
    }

    const onReset = (getValues, setValue) => {
        clearFormFields(getValues, setValue)
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params[formName].SortByField,
            SortOrder: params[formName].SortOrder,
        }
        setParams(newParams, formName)
    }

    useEffect(() => {
        fetchFilterFields()
    }, [])

    return (
        <>
            <FormResultsSummary 
                filterResultsCount={filterResultsCount}
                filterResultsLoading={filterResultsLoading}
                filterExpanded={filterExpanded}
                isCollapsed={isCollapsed}
                disclaimer={disclaimer}
            />
            <div
                className={`explorerFilter 
                ${ filterExpanded && !isCollapsed ? 'is-visible' : 'is-hidden' }
                `}
            >
                <div className="content">
                    <Form
                        formWidth={width}
                        formName={formName}
                        formFields={filterFields}
                        filterResultsCount={filterResultsCount}
                        filterResultsLoading={filterResultsLoading}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onChange={fetchResultsCount}
                    />
                </div>
            </div>
        </>
    )
}

export default Filter
