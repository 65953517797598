import React, { useContext } from 'react'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../../elem/Tooltip'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from 'react-icons/fa'
import { AppStateContext } from '../../AppStateContext'
import SelectorButton from '../../../../elem/chart/SelectorButton'
import ChartHeaderButton from '../../../../elem/chart/ChartHeaderButton'

export default () => {
    const {
        resetZoom,
        isCollapsed,
        toggleCollapsed,
        displayMeasureTypeWindowDropdown,
        toggleMeasureTypeWindowDropdown,
        measurementTypeList,
        displayScatterLine,
        toggleScatterLine,
        waterLevelChartData,
    } = useContext(DataContext)

    const { expandedMenu, setExpandedMenu } = useContext(AppStateContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader draggableHeader'}>
            <div className="panelHeaderText">Water Level Measurements</div>
            <div className="panelButtons">
                {waterLevelChartData
                    && waterLevelChartData.length
                    && measurementTypeList
                    && measurementTypeList.length 
                ? (<>
                        <ChartHeaderButton
                            onClickFunction={() => toggleScatterLine(current => !current)}
                            label={!displayScatterLine ? `Show Trendlines` : `Hide Trendlines`}
                        />
                        <SelectorButton
                            label={'Measurement Type'}
                            displayWindowDropdown={displayMeasureTypeWindowDropdown}
                            toggleWindowDropdown={toggleMeasureTypeWindowDropdown}
                        />
                    </>
                ) : null}
                <ResetZoomButton resetFunction={resetZoom} />
                {expandedMenu === 'WaterLevelComposedChart' ? null :
                    (
                        <div
                            className={`panelHeaderIconDiv`}
                            data-tip={`Collapse / Expand`}
                            data-for={`displayCollapse`}
                            onClick={() => toggleCollapsed()}
                        >
                            <IconContext.Provider
                                value={{
                                    className: `panelHeaderIcon`,
                                }}
                            >
                                {isCollapsed ? (
                                    <IoIosArrowUp />
                                ) : (
                                    <IoIosArrowDown />
                                )}
                            </IconContext.Provider>
                            <Tooltip id={'displayCollapse'} />
                        </div>
                    )}
                <div
                    className={`panelHeaderIconDiv`}
                    data-tip={`${expandedMenu === 'WaterLevelComposedChart'
                        ? `Collapse`
                        : `Expand to Full Screen`
                        }`}
                    data-for={`waterLevelChartExpand`}
                    onClick={() => setExpandedMenu(current => current === 'WaterLevelComposedChart' ? null : 'WaterLevelComposedChart')}
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon`,
                        }}
                    >
                        {expandedMenu === 'WaterLevelComposedChart' ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                    </IconContext.Provider>
                    <Tooltip id={'waterLevelChartExpand'} />
                </div>
            </div>
        </PanelHeader>
    )
}
