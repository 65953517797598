import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Scatter,
    Label,
    CartesianGrid,
    // ReferenceLine,
    ResponsiveContainer,
    Legend,
    ReferenceArea,
} from 'recharts'

import { DataContext } from './DataContext'
import Message from '../../../../elem/chart/Message'
import Spinner from '../../../../elem/Spinner'
import ChartTooltip from '../../../../elem/chart/Tooltip'
import { AppStateContext } from '../../AppStateContext'
import { dateToString } from '../../../../../utils/dateUtils'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
import { roundToFourDigits } from '../../../../../utils/chart/values'

const aggregateSeries = [
    {
        name: 'Maximum',
        color: '#ff7f00',
        dataKey: 'Maximum',
        type: `line`,
    },
    {
        name: 'Minimum',
        color: '#984ea3',
        dataKey: 'Minimum',
        type: `line`,
    },
    {
        name: 'Median',
        color: '#377eb8',
        dataKey: 'Median',
        type: `line`,
    },
    {
        name: 'Number of Measurements',
        color: '#377eff',
        dataKey: 'NumberOfSamples',
        type: `hidden`,
    }
]
//get the data properties that will be displayed in the chart as part of the tooltip but not as lines or dots in the chart 
const extraTooltipFields = aggregateSeries.filter(x => x.type === `hidden`)

export default () => {
    const {
        waterLevelAggregateData,
        loading,
        tooManyFeatures,
        zoomTrigger,
        isCollapsed,
        selectedMeasurementType
    } = useContext(DataContext)

    const { mapState: { selectedFeatures } } = useContext(AppStateContext)
    const facilityName = `Site`

    const [data, setData] = useState([])

    const [left, setLeft] = useState('dataMin')
    const [right, setRight] = useState('dataMax')
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        const filterFunction = (x) =>
            !x.includes('NumberOfSamples')
            && !x.includes('MeasurementTypeDisplay')
            && !x.includes('MeasurementType')
            && !x.includes('HasWaterLevelInMoreThanOneDate')
            && !x.includes('DateString')
            && !x.includes(`WaterLevelQualityCode`)
        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, filterFunction, 0, data, 'DateString')
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setRight('dataMax')
        setLeft('dataMin')
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])

    useEffect(() => {
        if (waterLevelAggregateData && waterLevelAggregateData.length) {
            setData(
                selectedMeasurementType
                    ? waterLevelAggregateData
                        .filter(x => x.MeasurementType === selectedMeasurementType.MeasurementType)
                        .map(
                            d => ({
                                ...d,
                                DateString: dayjs(d.DateString)
                                    .toDate()
                                    .getTime(),
                            })
                        )
                    : []
            )
        } else {
            setData([])
        }
    }, [waterLevelAggregateData, selectedMeasurementType])

    const summaryData = useMemo(() => {
        const dateFilteredData = right && right !== "dataMax" && left && left !== "dataMin"
            ? data.filter(x => x['DateString'] < right && x['DateString'] > left - 1)
            : data
        var maximumArray = dateFilteredData.map(x => x.Maximum)
        var minimumArray = dateFilteredData.map(x => x.Minimum)
        var quantityArray = dateFilteredData.map(x => x.NumberOfSamples)

        return ([
            {
                code: 'max',
                label: 'Maximum',
                value: Math.max(...maximumArray),
            },
            {
                code: 'min',
                label: 'Minimum',
                value: Math.min(...minimumArray),
            },
            {
                code: 'quantity',
                label: 'Number of Measurements',
                value: quantityArray.reduce((cur, acc) => cur + acc, 0),
            },
        ])
    }, [data, left, right])

    // construct chart
    const yAxisLabel = useMemo(() =>
        selectedMeasurementType && selectedMeasurementType.MeasurementTypeDisplay
            ? selectedMeasurementType.MeasurementTypeDisplay
            : 'Measurement Type'
        , [selectedMeasurementType])

    const xAxisLabel = 'Measurement Date'

    //get the data properties that will be displayed in the chart as lines or dots
    const graphDataPoints = useMemo(() => aggregateSeries.filter(x => x.type === 'line'), [aggregateSeries])

    if (loading && !isCollapsed) {
        return <Spinner extraClass="chartWrapper" />
    }

    if (tooManyFeatures) {
        return (
            <Message isCollapsed={isCollapsed}
                text={`Too Many ${facilityName}s Selected`}
                subText={`Select less than 2100 ${facilityName.toLowerCase()}s to generate aggregate statistics`}
            />
        )
    }

    if (!(selectedFeatures.length)) {
        return <Message isCollapsed={isCollapsed}
            text={'No Sites Selected'}
            subText={`Select up to 2100 ${facilityName.toLowerCase()}s with water level measurements within the past 2 years to generate aggregate statistics`}
        />
    }

    if (
        selectedFeatures.length &&
        !(waterLevelAggregateData && waterLevelAggregateData.length)
    ) {
        return (
            <Message isCollapsed={isCollapsed}
                text={'No Recent Water Level Data Available'}
                subText={`Select up to 2100 ${facilityName.toLowerCase()}s with water level measurements within the past 2 years to generate aggregate statistics`}
            />
        )
    }

    // shared chart props
    const animationDuration = 200

    // create lines for min, max, and median water levels
    const lines = graphDataPoints.map(({ name, dataKey, color }, idx) => (
        <Line
            key={`time-series-${name}`}
            type="monotone"
            dataKey={dataKey}
            name={name}
            connectNulls={true}
            unit={''}
            stroke={color}
            strokeWidth={0}
            activeDot={{ r: 5, fill: color, stroke: color }}
            dot={{ r: 1, stroke: color, fill: color }}
            animationDuration={animationDuration}
        />
    ))

    // create data points for number of samples
    // const numberOfSamples = (<Line 
    //     key={`time-series-numberOfSamples`}
    //     type="monotone"
    //     dataKey={'NumberOfSamples'}
    //     name={'Number of Samples'}
    //     stroke={'blue'}
    //     unit={''}
    //     strokeWidth={0}
    //     opacity={0}
    //     legendType={'none'}
    //     dot={false}
    //     activeDot={false}
    //  />)

    const dots = graphDataPoints.map(({ name, dataKey, color }, idx) => (
        <Scatter
            key={`best-fit-${name}`}
            dataKey={dataKey}
            lineType="fitting"
            name={'NoTooltipItem'}
            line={{
                stroke: color,
                strokeWidth: 1,
            }}
            fill="none"
            animationDuration={animationDuration}
        />
    ))

    const RenderLegend = props => {
        const { payload, summaryData } = props
        return (
            <div className='waterLevelAggregateLegend'>
                <ul className="recharts-default-legend legendWrapper aggregateLegendWrapper legendItemContainer">
                    {payload.map((entry, index) => (entry.value !== 'NoTooltipItem' && entry.value !== 'Number of Samples') ? (
                        <li className="recharts-legend-item legendItem" key={`item-${index}`}>
                            <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                                <path strokeWidth="4" fill="none" stroke={entry.color} d="M0,16h10.666666666666666
                                    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                                    H32M21.333333333333332,16
                                    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16" className="recharts-legend-icon" />
                            </svg>
                            <span className="recharts-legend-item-text">{entry.value}</span>
                        </li>
                    ) : null)}
                </ul>
                <div className='columns legendItemContainer'>
                    {summaryData.map(x => (
                        <div className='column recharts-legend-item legendItem flex-basis-auto'>
                            <span className="recharts-legend-item-text"><b>{x.label}:</b> {x.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className="chart">
            <div className={isCollapsed ? `is-collapsed` : `aggregateChartWrapper`}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 20,
                            bottom: 25,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            type="number"
                            dataKey="DateString"
                            domain={[left, right]}
                            tickFormatter={unixTime => dateToString(unixTime)}
                            allowDataOverflow={true} // WNS-155
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            reversed={true}
                            type="number"
                            domain={[bottom, top]}
                            interval='preserveStartEnd'
                            tickFormatter={v => roundToFourDigits(v)}
                            yAxisId={0}
                            allowDataOverflow={true} // WNS-155
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={0}
                                position="insideBottomLeft"
                                className="nitrateAggregateChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={90} />} extraColumns={extraTooltipFields} allowEscapeViewBox={{ x: true, y: true }} />
                        <Legend
                            verticalAlign="bottom"
                            height={70}
                            width={`inherit`}
                            content={<RenderLegend summaryData={summaryData} />}
                        />
                        {lines}
                        {dots}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
