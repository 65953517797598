import { Circle as CircleStyle, Fill, Stroke, Icon, Style, Text as TextStyle } from 'ol/style'
import VectorImage from 'ol/layer/VectorImage'
import { AG, DO, IN,OB, OG, OTH, PU, TST, UN } from './layers/mapIconIndex'

const activeFill = new Fill({ 
    color: 'rgb(50, 115, 220)',
})

const activeStroke = new Stroke({
    color: '#ffffff',
    width: 1,
})

const inactiveFill = new Fill({
    color: '#9C9C9C',
})

// const inactiveStroke = new Stroke({
//     color: 'rgb(0, 100, 255)',
//     width: 1,
// })

const sizes = [1,2,3,4,5,6,7,8]

const activeStyle = { stroke: activeStroke, fill: activeFill }
const inactiveStyle = { fill: inactiveFill }
const styles = {
    1: sizes.reduce((acc, curr) => {
        return {
            ...acc,
            [curr]: new Style({
                image: new CircleStyle({
                    radius: curr + 2,
                    ...activeStyle,
                }),
                zIndex: 10
            })
        }
    }, {}),
    0: sizes.reduce((acc, curr) => {
        return {
            ...acc,
            [curr]: new Style({
                image: new CircleStyle({
                    radius: curr,
                    ...inactiveStyle,
                }),
                zIndex: 10
            })
        }
    }, {}),
    invisible: new Style({
        image: new CircleStyle({
            stroke: null,
            fill: null,
        }),
    }),
    highlighted: new Style({
        image: new CircleStyle({
            stroke: new Stroke({ color: 'rgb(100, 80, 210)', width: 2 }),
            fill: new Fill({ color: 'rgb(210, 80, 210)' }),
            radius: 5,
        }),
        zIndex: 12,
    }),
}

const drawStyles = {
    polygonStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)', width: 2}),
        fill: new Fill({color: 'rgba(235, 162, 80, 0.3)'}),
    }),
    pointerStylesHighlighted: new Style ({
        image: new CircleStyle({
            ...activeStyle,
            radius: 7,
        })
    }),
    pointerStylesSelected: new Style ({
        image: new CircleStyle({
            ...inactiveStyle,
            radius: 7,
        })
    }),
    boundingBoxStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)'}),
    }),
}

const wellLegendStyles = [
    { fillColor: 'grey', range: 'Not Applicable' },
    { fillColor: 'blue', range: 'Greater than Average' },
    { fillColor: 'orange', range: 'Less than Average' },
    { fillColor: 'green', range: 'Equal to Average' },
]

const siteTypeIcons = [
    { code: 'AG',  label: 'Agricultural', icon: AG, color: 'grey'},
    { code: 'DO',  label: 'Domestic', icon: DO, color: 'green'},
    { code: 'IN',  label: 'Industrial', icon: IN, color: 'orange'},
    { code: 'OB',  label: 'Observation', icon: OB, color: 'blue'},
    { code: 'OG',  label: 'Observation / Monitoring', icon: OG, color: 'purple'},
    { code: 'PU',  label: 'Public', icon: PU, color: 'black'},
    { code: 'UN',  label: 'Undefined', icon: UN, color: 'grey' },
    { code: 'TST', label: 'Test', icon: TST, color: 'orange'},
    { code: 'OTH', label: 'Other', icon: OTH, color: 'teal'},
]
//if the position of the assets file were to ever change this would need to change as well
const siteTypeLegendStyles = [ ...siteTypeIcons.map(x => ({ fillColor: x.icon, range: x.label, strokeColor: null })) ]

const wellColors = ['grey', 'blue', 'orange', 'green']
const wellStyles = wellColors.reduce((acc, curr) => {
    const color = curr
    const options = sizes.reduce((sizeOptions, size) => {
        return {
            ...sizeOptions,
            [size]: new Style({
                image: new CircleStyle({
                    fill: new Fill({
                        color
                    }),
                    radius: size, 
                    stroke: new Stroke({
                        color: 'black',
                        width: 0.2 * size,
                    }),
                })
            })
        }
    }, {})
    return {
        ...acc,
        [color]: options
    }
}, {})

const siteTypeStyles = siteTypeIcons.reduce((symbolOptions, facility) => {
        const { code, icon } = facility
        const options = sizes.reduce((sizeOptions, size) => {
            const imgSize = 100
            const s = ( size + 1 ) * 15 / imgSize
            return {
                ...sizeOptions,
                [size]: new Style({
                    image: new Icon({
                        src: icon,
                        scale: s,
                        // color,
                        anchor: [0.5, 0.5],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                    }),
                })
            }
        }, {})
        return {
            ...symbolOptions,
            [code]: options
        }
    }, {})

const addTextToStyle = (style, feature, zoomLevel) => { //see line 74 in https://openlayers.org/en/latest/examples/vector-labels.html 
    let text = feature.get('USGS_ID')
    
    // dev note: zoom level 7 corresponds approximately to scale 1:70,000
    if (zoomLevel < 7) return style // if zoom surpasses certain threshold return same style withouth text

    if (typeof text === 'number') {
        text = text.toString()
    }
    style.text_ = new TextStyle({ //add text to style 
        font: 4,
        text: text,
        offsetY: -15
    }) 
    return style 
  }

const unthemedStyles = sizes.reduce((acc, curr) => {
    return {
        ...acc,
        [curr]: new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'grey'
                }),
                radius: curr
            })
        })
    }
}, {})

const getBaseDataLayer = (dataLayerName, getStyleFunction, source) => {
    // add the themed facility layer
    const baseDataLayer = new VectorImage({
        source,
        visible: true,
        style: getStyleFunction,
        minResolution: 0,
        zIndex: 100,
    })
    baseDataLayer.set('name', dataLayerName)
    return baseDataLayer
}

const getLayerLegendObject = (dataLayerName, legendStyles, groupName) => ({
    [dataLayerName]: {
        display: false,
        expanded: false,
        unique: true,
        LayerName: dataLayerName,
        layerGroupName: groupName,
        loaded: true,
        // shape: 'circle',
        StyleArray: JSON.stringify(legendStyles),
        LayerType: 'THEME'
    }
})

const styleFunction = function(theme, feature, zoomLevel) {
    const selected = feature.get('selected')
    const displayed = feature.get('displayed')
    const highlighted = feature.get('highlighted')
    if (displayed) {
        if (highlighted) {
            return addTextToStyle(styles['highlighted'], feature, zoomLevel)
        }

        const size = Math.ceil(zoomLevel)
        if (selected) {
            return addTextToStyle(styles[selected][size], feature, zoomLevel)
        }

        let styleWihoutText
        if (theme === 'waterLevelVariation') {
                styleWihoutText = wellStyles[feature.get('WaterLevelVariationFromAverage')][size]
        } else if (theme === 'siteType') {
            const facilityType = feature.get('FacilityType')
            styleWihoutText = siteTypeStyles[facilityType][size]
        } else {
            styleWihoutText = unthemedStyles[size]
        }
        
        // switch (theme) {
        //     case 'waterLevelVariation':
        //         styleWihoutText = wellStyles[feature.get('WaterLevelVariationFromAverage')][size]
        //         break;
        //     case 'siteType':
        //         const facilityType = feature.get('FacilityType')
        //         styleWihoutText = siteTypeStyles[facilityType][size]
        //         break;
        //     default:
        //         styleWihoutText = unthemedStyles[size]
        //         break;
        // }
        const styleWithText = addTextToStyle(styleWihoutText, feature, zoomLevel)
        return styleWithText
    }
    return styles.invisible[0]
}

export { drawStyles, wellLegendStyles, getBaseDataLayer, getLayerLegendObject, siteTypeLegendStyles }
export default styleFunction
