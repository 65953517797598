import React from 'react'

import DataContextProvider from './DataContext'
import Panel from '../../../../elem/panel/Panel'
import { waterLevelPanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
import WaterLevelHeader from './WaterLevelHeader'
import WaterLevelChart from './WaterLevelChart'
import MeasurementTypeDropdown from './MeasurementTypeDropdown'

const WaterLevel = ({ width }) => {
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <WaterLevelHeader />
                    <MeasurementTypeDropdown />
                    <WaterLevelChart />
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default WaterLevel