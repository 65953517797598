import React, { useContext } from 'react'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext} from './DataContext'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import CollapseButton from '../../../../elem/list/CollapseButton'
import { AppStateContext } from '../../AppStateContext'
import ExpandButton from '../../../../elem/list/ExpandButton'
import SelectorButton from '../../../../elem/chart/SelectorButton'

export default ({ name }) => {
    const {
        isCollapsed,
        setCollapsed,
        resetZoom,
        measurementTypeList,
        displayMeasureTypeWindowDropdown,
        toggleMeasureTypeWindowDropdown,
        waterLevelAggregateData,
    } = useContext(DataContext)
    const {expandedMenu, setExpandedMenu} = useContext(AppStateContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Water Level Aggregates</div>
            <div className="panelButtons">
                {measurementTypeList.length 
                    && waterLevelAggregateData 
                    && waterLevelAggregateData.length
                ? (
                    <SelectorButton    
                        label={'Measurement Type'}
                        displayWindowDropdown={displayMeasureTypeWindowDropdown}
                        toggleWindowDropdown={toggleMeasureTypeWindowDropdown}
                    />
                ) : null}
                <ResetZoomButton resetFunction={resetZoom}/>
                {expandedMenu === name ? null :
                (
                    <CollapseButton
                        toggleCollapsed={() => setCollapsed(current => !current)}
                        isCollapsed={isCollapsed}
                    />
                )}
                <ExpandButton name={name}
                    expandedMenu={expandedMenu}
                    setExpandedMenu={setExpandedMenu}
                />
            </div>
        </PanelHeader>
    )
}
