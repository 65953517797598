import React, { useContext } from 'react'

import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../../elem/chart/AnalyteSelectorButton'
import ResetZoomButton from '../../../../elem/chart/ResetZoomButton'
import ResetDropdownButton from '../../../../elem/chart/ResetDropdownButton'

import { DataContext } from './../DataContext'
import { AppStateContext } from '../../AppStateContext'
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from 'react-icons/fa'
import Tooltip from '../../../../elem/chart/Tooltip'
import ChartHeaderButton from '../../../../elem/chart/ChartHeaderButton'
import ExpandButton from '../../../../elem/list/ExpandButton'

const ChartControls = () => {
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        resetZoom,
        resetExpanded,
        toggleResetExpanded,
        visibleChartTab,
        displayScatterLine,
        toggleScatterLine,
    } = useContext(DataContext)
    
    const {expandedMenu, setExpandedMenu} = useContext(AppStateContext)
    return (
        <div className="chartControlsWrapper columns">
            <div className="column is-justify-content-center">
                {
                    visibleChartTab === 'sampleResult' 
                    ? (
                        <>
                            <ResetDropdownButton 
                                inHeader={false} 
                                resetExpanded={resetExpanded}
                                toggleResetExpanded={toggleResetExpanded} 
                            />
                            <AnalyteSelectorButton
                                inHeader={false} 
                                displayAnalyteWindowDropdown={displayAnalyteWindowDropdown}
                                toggleAnalyteWindowDropdown={toggleAnalyteWindowDropdown}
                            />
                            <TimeWindowSelectorButton
                                inHeader={false} 
                                timeWindow={timeWindow}
                                displayTimeWindowDropdown={displayTimeWindowDropdown}
                                toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                            />
                        </>
                    ) 
                    : (
                        <div className='columns is-justify-content-center'>
                            <div>
                                <ChartHeaderButton 
                                    onClickFunction={() => toggleScatterLine(current => !current)} 
                                    label={!displayScatterLine ? `Show Trendline` : `Hide Trendline`}
                                />
                            </div>
                            <div>
                                <ResetZoomButton resetFunction={resetZoom} />
                            </div>
                        </div>
                    )
                }
            </div>            
            <ExpandButton name={`DetailsWaterLevelChart`}
                expandedMenu={expandedMenu}
                setExpandedMenu={setExpandedMenu}
            />
    </div>

    )
}

export default ChartControls