import React, { useEffect, useRef, useState } from 'react'
import { dateToString } from '../../../utils/dateUtils'
import { formatTooltipValue } from '../../../utils/chart/values'

const getExtraTooltipRows = (extraColumns, payload) => {
    if (extraColumns && extraColumns.length) {
        const p = payload && payload.length && payload[0] && payload[0].payload ? payload[0].payload : null
        if (p) {
            const payloadData = extraColumns.filter(x => Object.keys(p).includes(x.dataKey)).map(x => ({...x, value: p[x.dataKey]}))
            return payloadData
        }
    } 
        return []
}

const TooltipItem = ({ tooltipData }) => {
    return tooltipData.name !== 'NoTooltipItem' ? (
        <li style={{ color: tooltipData.color }}>
            <span className="customTooltipItemName">
                {tooltipData.name}
            </span>
            <span> : </span>
            <span className="customTooltipItemValue">
                {formatTooltipValue(tooltipData.value)}
            </span>
            {tooltipData.unit && <span className="customTooltipItemUnit">{` ${tooltipData.unit}`}</span>}
        </li>
    ) : null
}

export default ({ active, payload, label, viewBox, coordinate, stagger, extraColumns, format="MM/DD/YYYY"}) => {
    const [top, setTop] = useState(0)
    const tooltipRef = useRef()

    useEffect(() => {
        if (tooltipRef && tooltipRef.current && active) {
            const gridBottom = viewBox.top + viewBox.height
            const yCoordinate = coordinate.y
            const tooltipHeight = tooltipRef.current.offsetHeight
            const tooltipBottom = tooltipHeight + yCoordinate
            if (tooltipBottom > gridBottom) {
                const t = -Math.round((tooltipBottom - gridBottom)) - (stagger ? stagger : 0)
                setTop(t)
            } else {
                setTop(0)
            }
        }
    }, [payload, active, viewBox, coordinate, tooltipRef])

    if (active) {
        const formattedLabel = dateToString(label, format)
        const extraTooltipRows = getExtraTooltipRows(extraColumns, payload)
        return (
            <div className="customTooltip" ref={tooltipRef} style={{top, position: 'relative'}}>
                <p className="customTooltipLabel">{formattedLabel}</p>
                <ul className="customTooltipList">
                    {payload && 
                        payload.filter(n => n.name !== 'none').map((x, idx) => (<TooltipItem tooltipData={x} key={idx} />))}    
                    {extraTooltipRows &&
                            extraTooltipRows.map((x, idx) => (<TooltipItem tooltipData={x} key={idx} />))}
                </ul>
            </div>
        )
    }
    return null
}
