import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react'

import withConfig from '../../../../wrappers/withConfig'
import { AppStateContext } from '../../AppStateContext'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import toast from '../../../../elem/Toast'
import getChartConfig from '../../../../../utils/chart/getChartConfig'
import {
    generateDateParams,
    getFieldDataInParams,
} from '../../../../../utils/chart/values'
import filterTimeData from '../../../../../utils/chart/timeWindow'
import { ParameterContext } from '../../../../wrappers/ParameterContext'

const DataContext = createContext(null)

export default withConfig(({ config, children }) => {
    const { mapState } = useContext(AppStateContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { params } = useContext(ParameterContext)
    const [chartData, setChartData] = useState([])
    const [measurementTypeList, setMeasurementTypeList] = useState([])
    const [tooManyFeatures, setTooManyFeatures] = useState(false)
    const [loading, setLoading] = useState(false)
    const [zoomTrigger, setZoomTrigger] = useState(false)
    const [isCollapsed, setCollapsed] = useState(false)
    const [displayScatterLine, toggleScatterLine] = useState(true)
    const [selectedMeasurementType, toggleSelectedMeasurementType] = useState({
        MeasurementTypeDisplay: null,
        MeasurementType: null,
    })
    const [
        displayMeasureTypeWindowDropdown,
        toggleMeasureTypeWindowDropdown,
    ] = useState(false)
    const [chartDataAbortController, setChartDataAbortController] = useState(
        new AbortController()
    )
    const { selectedFeatures } = mapState
    const sortedFeatures = useMemo(() =>
        selectedFeatures
            .map(x => x.get('FacilityID'))
            .sort()
            .toString()
    , [selectedFeatures]
    )

    const { API_URL } = config

    const { dateField } = getChartConfig(config, 'WATER_LEVEL')
    const dateParams = useMemo(() => {
        const d = generateDateParams(params, 'waterLevel', `${dateField}`)
        return d.some(x => x !== null) ? d : null
    }, [params, dateField])

    const waterLevelQualityParam = useMemo(
        () =>
            getFieldDataInParams(params, 'waterLevel', 'WaterLevelQualityCode'),
        [params]
    )
    
    const fetchMeasureListData = useCallback(() => {
            const abort = new AbortController()
            setChartDataAbortController(abort)

            const [startDate, endDate] = dateParams ? dateParams : [null, null]
            const facilityidList = encodeURI(sortedFeatures)
            const body = JSON.stringify({
                FacilityIDs: facilityidList,
                MeasurementType: null,
                WaterLevelQualityCode: waterLevelQualityParam,
                startDate,
                endDate,
            })
            authenticatedFetch(`${API_URL}/waterLevelaggregate/getMeasurementTypeList`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                signal: abort.signal,
                body,
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    if (response.measurementTypeList && response.measurementTypeList.length) {
                        setMeasurementTypeList(response.measurementTypeList)
                        toggleSelectedMeasurementType(response.measurementTypeList[0])                        
                    } else {
                        setMeasurementTypeList([])
                        toggleSelectedMeasurementType({
                            MeasurementTypeDisplay: null,
                            MeasurementType: null,
                        })
                    }
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Water Level Aggregate Chart: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    // if (e && e.name === 'AbortError') setLoading(true)
                    // else setLoading(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [selectedMeasurementType, dateParams, waterLevelQualityParam, sortedFeatures]
    )
    
    const fetchData = useCallback(() => {
            const abort = new AbortController()
            setChartDataAbortController(abort)
            setLoading(true)
            
            const [startDate, endDate] = dateParams ? dateParams : [null, null]
            const facilityidList = encodeURI(sortedFeatures)
            const body = JSON.stringify({
                FacilityIDs: facilityidList,
                MeasurementType:
                    selectedMeasurementType &&
                    selectedMeasurementType.MeasurementType,
                WaterLevelQualityCode: waterLevelQualityParam,
                startDate,
                endDate,
            })
            authenticatedFetch(`${API_URL}/waterLevelaggregate`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                signal: abort.signal,
                body,
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setChartData(response)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Water Level Aggregate Chart: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    // if (e && e.name === 'AbortError') setLoading(true)
                    // else setLoading(false)
                    setChartData([])
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [selectedMeasurementType, dateParams, waterLevelQualityParam, sortedFeatures]
    )

    useEffect(() => {
        chartDataAbortController.abort()
        if (sortedFeatures && sortedFeatures.length) {
            const sortedFeatureIds = sortedFeatures.split(',')
            if (sortedFeatureIds.length <= 2100) {
                setLoading(true)
                setTooManyFeatures(false)
                fetchMeasureListData()
            } else {
                setTooManyFeatures(true)
            }
        } else {
            // if there are no features,
            // set data to an empty array
            setChartData([])
            setMeasurementTypeList([])
            setLoading(false)
        }
    }, [sortedFeatures, waterLevelQualityParam, dateParams])
    
    const waterLevelAggregateData = useMemo(() => {
        if (chartData && chartData.data && chartData.data.length) {
            const dateFilteredData = dateParams
                ? filterTimeData(chartData.data, dateParams, dateField)
                : chartData.data
            return dateFilteredData
        }
        return []
    }, [chartData, dateParams])

    useEffect(() => {
        chartDataAbortController.abort()
        // WNS-196 - if there is no selected measurement type, do not fetch data
        if (selectedMeasurementType && selectedMeasurementType.MeasurementType) {
            fetchData()
        } else {
            setChartData([])
        }
    }, [selectedMeasurementType])

    const resetZoom = useCallback(() => {
        setZoomTrigger(!zoomTrigger)
    }, [zoomTrigger])

    return (
        <DataContext.Provider
            value={{
                loading,
                chartData,
                tooManyFeatures,
                resetZoom,
                isCollapsed,
                setCollapsed,
                displayMeasureTypeWindowDropdown,
                toggleMeasureTypeWindowDropdown,
                selectedMeasurementType,
                toggleSelectedMeasurementType,
                measurementTypeList,
                displayScatterLine,
                toggleScatterLine,
                waterLevelAggregateData,
                zoomTrigger,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
