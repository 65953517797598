import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import debounce from 'debounce'
import deepEqual from 'deep-equal'

import withConfig from '../../wrappers/withConfig'
import toast from '../Toast'
import {
    getQueryStringFromParams,
    removeOrderAndPageParamsFromOtherFields
} from '../../../utils/params'
import getFilterParamsFromForm from '../../../utils/form/getFilterParamsFromForm'

import { ParameterContext } from '../../wrappers/ParameterContext'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { AppStateContext } from '../../features/explorer/AppStateContext'


const DataContextProvider = ({ DataContext, formName, apiController, name, history, config, children }) => {
    const { params, setParams } = useContext(ParameterContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { mapState: { selectedFeatures, mapSelectionIds }, promotedRecords } = useContext(AppStateContext)
    const [tableData, setTableData] = useState([])
    const [tableMetadata, setTableMetadata] = useState({})
    const [filterFields, setFilterFields] = useState([])
    const [filterResultsCount, setFilterResultsCount] = useState()
    const [filterResultsLoading, setFilterResultsLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [dataAbortController, setDataAbortController] = useState(new AbortController())
    const [filterResultsAbortController, setFilterResultsAbortController] = useState(new AbortController())
    const associatedParams = useRef({})
    const associatedFeatures = useRef([])
    const activeMapSelectionIds = useRef(null)
    const { API_URL, ID_COLUMN } = config

    const fetchData = useCallback(params => {
        const selectedIds = encodeURI(selectedFeatures.map(x => x.get(ID_COLUMN)).toString())
        setLoading(true)
        const abort = new AbortController()
        setDataAbortController(abort)
        const paramString = getQueryStringFromParams(params)
        if (paramString && paramString.length >= 2000) { // only write to the URL if the querystring would be less than 2000 characters, most browsers will error out somewhere around that queryString length
            if (name === 'Well') {
                toast({
                    level: 'error',
                    message:
                        `The maximum number of applied filters has been exceeded. Please refine your search or download a copy of the database`,
                    alert: true
                })
            }
            setLoading(false)
            return
        }
        authenticatedFetch(`${API_URL}/${apiController}`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                signal: abort.signal,
                body: JSON.stringify({ selectedIds, mapSelectionIds, QueryStringParameters: paramString })
            })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const pathName = history.location.pathname
                history.push(`${pathName}?${paramString}`)
                setTableData(response.data)
                setTableMetadata(response.meta)
                setLoading(false)
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            `${name} List: ${(e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.')}`,
                    })
                    setLoading(false)
                }
                // if (e.message && e.message.includes(`The query processor ran out of internal resources`)) {
                //     toast({
                //         level: 'error',
                //         message:
                //             `The maximum number of applied filters has been exceeded. Please refine your search or download a copy of the database`,
                //         alert: true
                //     })
                //     setLoading(false)
                // }
            })
    }, [selectedFeatures, promotedRecords, mapSelectionIds])

    const fetchResultsDebounce = debounce((e, formData) => {
        // query for the updated count
        const newParams = {
            ...params,
            [apiController]: {
                ...getFilterParamsFromForm(formData)
            }
        }
        const abort = new AbortController()
        const selectedIds = encodeURI(selectedFeatures.map(x => x.get(ID_COLUMN)).toString())
        setFilterResultsAbortController(abort)
        const paramString = getQueryStringFromParams(newParams)
        if (paramString && paramString.length >= 2000) { // only write to the URL if the querystring would be less than 2000 characters, most browsers will error out somewhere around that queryString length
            setFilterResultsLoading(false)
            return
        }
        authenticatedFetch(`${API_URL}/${apiController}/count`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers':
                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                },
                signal: abort.signal,
                body: JSON.stringify({ selectedIds, mapSelectionIds, QueryStringParameters: paramString })
            })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const { meta } = response
                const { count } = meta
                setFilterResultsCount(count)
                setFilterResultsLoading(false)
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            `${name} List:
                            ${(e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.')}`,
                    })
                    setFilterResultsLoading(false)
                }
            })
    }, 3000, false)

    const fetchResultsCount = useCallback((e, formData) => {
        filterResultsAbortController.abort()
        setFilterResultsLoading(true)
        fetchResultsDebounce.clear()
        fetchResultsDebounce(e, formData)
    }, [filterResultsAbortController, mapSelectionIds])

    const fetchFilterFields = () => {
        authenticatedFetch(`${API_URL}/${apiController}/filterFields`)
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFilterFields(response.data)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        `${name} Filter: 
                        ${(e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.')}`,
                })
            })
    }

    // fetch data on parameter changes
    useEffect(() => {
        const newParams = removeOrderAndPageParamsFromOtherFields(params, formName)
        // WNS-176
        if (newParams && newParams.app) {
            for (const promoted in newParams.app)
                if (promoted !== `${name}Promoted`)
                    delete newParams.app[promoted]
        }

        if (!deepEqual(associatedParams.current, newParams) || (!deepEqual(associatedFeatures.current, selectedFeatures)
            && promotedRecords[name]) || !(deepEqual(activeMapSelectionIds.current, mapSelectionIds))
        ) {
            dataAbortController.abort()
            fetchData(params)
            associatedParams.current = newParams
            associatedFeatures.current = selectedFeatures
            activeMapSelectionIds.current = mapSelectionIds
        }
    }, [params, dataAbortController, selectedFeatures, promotedRecords[name], mapSelectionIds])

    return (
        <DataContext.Provider
            value={{
                data: tableData,
                meta: tableMetadata,
                formName,
                params,
                setParams,
                filterFields,
                fetchData,
                fetchFilterFields,
                fetchResultsCount,
                filterResultsCount,
                filterResultsLoading,
                loading,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export default withRouter(withConfig(DataContextProvider))
