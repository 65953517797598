import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import MeasurementTypeSelector from '../../../../elem/chart/MeasurementTypeSelector'

export default () => {
    const {
        selectedMeasurementType,
        toggleSelectedMeasurementType,
        displayMeasureTypeWindowDropdown,
        toggleMeasureTypeDropdown,
        measurementTypeList
    } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)

    if (!measurementTypeList || !measurementTypeList.length) {
        return null
    }

    return (
        <MeasurementTypeSelector
            selectedMeasurementType={selectedMeasurementType}
            toggleSelectedMeasurementType={toggleSelectedMeasurementType}
            displayMeasureTypeWindowDropdown={displayMeasureTypeWindowDropdown}
            toggleMeasureTypeDropdown={toggleMeasureTypeDropdown}
            measurementTypeList={measurementTypeList}
            colorClass={colorClass}
        />
    )
}
