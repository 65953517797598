import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react'
import {
    ComposedChart,
    Line,
    YAxis,
    XAxis,
    Tooltip,
    Label,
    CartesianGrid,
    ResponsiveContainer,
    ReferenceArea,
    Legend,
    Area,
} from 'recharts'

import { DataContext } from './DataContext'
import Spinner from '../../../../elem/Spinner'
import ChartTooltip from '../../../../elem/chart/Tooltip'
// import { dateToString } from '../../../../../utils/dateUtils'
// import { AppStateContext } from '../../AppStateContext'
import withConfig from '../../../../wrappers/withConfig'
import dayjs from 'dayjs'
import palette from '../../../../../utils/chart/palette'
import { getAxisYDomain } from '../../../../../utils/chart/slice'
// import getChartConfig from '../../../../../utils/chart/getChartConfig'
import { roundToFourDigits } from '../../../../../utils/chart/values'
import { monthAndDayTickFormatter, minWaterDay, maxWaterDay, calculateMonthDayTicks, monthAndDayFormat } from '../../../../../utils/chart/time'


const CustomLegend = ({ legendPayload }) => {
    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`line-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span className="recharts-legend-item-text">
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}


const uniqueSeries = [
    {
        column: 'WellAboveNormal',
        name: 'Well Above Normal',
        color: palette[0],
        type: 'area'
    },
    {
        column: 'AboveNormal',
        name: 'Above Normal',
        color: palette[3],
        type: 'area'
    },
    {
        column: 'Normal',
        name: 'Normal',
        color: palette[1],
        type: 'area'

    },
    {
        column: 'BelowNormal',
        name: 'Below Normal',
        color: palette[2],
        type: 'area'
    },
    {
        column: 'WellBelowNormal',
        name: 'Well Below Normal',
        color: palette[4],
        type: 'area'
    },
    {
        column: 'CurrentValue',
        name: 'Current Water Year',
        color: 'gray',
        type: 'line'
    },
    {
        column: 'SampleCount',
        name: 'Number of Water Level Measurements',
        color: palette[8],
        type: 'hidden'
    }
]

const extraTooltipFields = uniqueSeries.filter(x => x.type === 'hidden')

export default withConfig((
    // { config }
) => {
    const {
        hydrographChartData,
        loading,
        zoomTrigger
    } = useContext(DataContext)

    // const { valueField, keyField, facilityName } = getChartConfig(config, 'WATER_LEVEL')
    const dateField = 'Date'
    const facilityName = 'Sample Point'

    const [data, setData] = useState([])

    const [left, setLeft] = useState(minWaterDay)
    const [right, setRight] = useState(maxWaterDay)
    const [refAreaLeft, setRefAreaLeft] = useState('')
    const [refAreaRight, setRefAreaRight] = useState('')
    const [top, setTop] = useState('dataMax')
    const [bottom, setBottom] = useState('dataMin')

    const zoom = useCallback(() => {
        let RAL = refAreaLeft
        let RAR = refAreaRight
        if (RAL === RAR || RAR === '') {
            RAL = ''
            RAL = ''
            return
        }

        // xAxis domain
        if (RAL > RAR) {
            const temp = RAL
            RAL = RAR
            RAR = temp
        }

        // yAxis domain
        const leftAxisDomain = getAxisYDomain(RAL, RAR, (x) => !x.includes(dateField) && !uniqueSeries.filter(x => x.type === 'hidden').map(x => x.name).includes(x), 0, data, dateField)
        setBottom(leftAxisDomain[0])
        setTop(leftAxisDomain[1])

        setLeft(RAL)
        setRight(RAR)
        setRefAreaRight('')
        setRefAreaLeft('')
    }, [refAreaLeft, refAreaRight, data])

    const zoomOut = useCallback(() => {
        setRefAreaLeft('')
        setRefAreaRight('')
        setLeft(minWaterDay)
        setRight(maxWaterDay)
        setTop('dataMax')
        setBottom('dataMin')
    }, [data])

    useEffect(() => {
        zoomOut()
    }, [zoomTrigger])


    const seriesData = useMemo(() => {
        if (hydrographChartData && hydrographChartData.length) {
            const d = hydrographChartData.map(x => (
                {
                    Date: new dayjs(`${x["Month"]}-${x["Day"]}-${x["Month"] < 10 ? '2002' : '2001'}`).toDate().getTime(), // Hack to get the dates aligned over a water year
                    ...x
                })).map(x => {
                    const data = uniqueSeries.reduce((acc, curr) => {
                        const { name, type, column } = curr
                        if (type === 'area') {
                            const minValue = x[`${column}Min`]
                            const maxValue = x[`${column}Max`]
                            return {
                                ...acc,
                                [name]: [minValue, maxValue]
                            }
                        } else {
                            return {
                                ...acc,
                                [name]: x[column]
                            }
                        }
                    }, {})
                    return {
                        ...data,
                        "Date": x.Date
                    }
                })
                .sort((a, b) => b.Date - a.Date)
            return d
        }
        return hydrographChartData
    }, [hydrographChartData, uniqueSeries])

    useEffect(() => {
        if (seriesData && seriesData.length) {
            // const d = seriesData.map(x => ({
            //     ...x,
            //     [dateField]: dayjs(x[dateField])
            //         .toDate()
            //         .getTime(),
            // }))
            setData(seriesData)
        }
    }, [seriesData])

    const legendData = useMemo(
        () =>
            uniqueSeries &&
            uniqueSeries.filter(x => x.type !== 'hidden').map(({ name, color }) => ({
                color,
                type: 'line',
                dataKey: name,
                value: name,
            })),
        [uniqueSeries]
    )


    // shared chart props
    const animationDuration = 200

    // create a line for the current data
    const lines = useMemo(() => {
        return uniqueSeries.filter(x => x.type === 'line').map(({ name, color }, idx) => (
            <Line
                key={`time-series-${name}-${idx}`}
                type="monotone"
                dataKey={name}
                name={name}
                unit={`ft bls`}
                stroke={color}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 5, fill: color, stroke: color }}
                animationDuration={animationDuration}
            />
        ))
    }, [uniqueSeries])

    // create stacked area items for each of the percentile groups
    const areas = useMemo(() => {
        return uniqueSeries.filter(x => x.type === 'area').map(({ name, color }, idx) => (
            <Area
                key={`time-series-${name}-${idx}`}
                type="monotone"
                dataKey={name}
                name={name}
                unit={`ft bls`}
                stroke={color}
                fill={color}
                strokeWidth={0}
                activeDot={{ r: 5, fill: color, stroke: color }}
                animationDuration={animationDuration}
            />
        ))
    }, [uniqueSeries])

    const ticks = useMemo(() =>
        calculateMonthDayTicks(left, right)
        , [left, right])

    if (loading || typeof (facilityName) === 'undefined') {
        return <Spinner extraClass="chartWrapper" />
    }

    // construct chart
    const yAxisLabel = `Depth to Water (ft bls)`
    const xAxisLabel = 'Measurement Date'



    return (
        <div className="chart">
            <div className="aggregateChartWrapper">
                <ResponsiveContainer width="100%" minHeight={'250px'}>
                    <ComposedChart
                        data={data.slice()}
                        margin={{
                            top: 10,
                            right: 20,
                            left: 25,
                            bottom: 25,
                        }}
                        onMouseDown={e => e && e.activeLabel && setRefAreaLeft(e.activeLabel)}
                        onMouseMove={e => e && e.activeLabel && refAreaLeft && setRefAreaRight(e.activeLabel)}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={() => zoom()}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            allowDataOverflow={true} // WNS-155
                            type="number"
                            dataKey={"Date"}
                            domain={[left, right]}
                            interval={0}
                            ticks={ticks}
                            tickFormatter={x => monthAndDayTickFormatter(x, left, right, minWaterDay, maxWaterDay)}
                        >
                            <Label
                                value={xAxisLabel}
                                offset={-10}
                                position="insideBottom"
                                className="nitrateChartXAxisLabel"
                            />
                        </XAxis>
                        <YAxis
                            reversed={true}
                            interval='preserveStartEnd'
                            allowDataOverflow={true} // WNS-155 
                            type="number"
                            domain={[bottom, top]}
                            tickFormatter={v => roundToFourDigits(v)}
                            yAxisId={0}
                        >
                            <Label
                                value={yAxisLabel}
                                angle={-90}
                                offset={20}
                                position="insideLeft"
                                className="nitrateChartYAxisLabel"
                            />
                        </YAxis>
                        <Tooltip content={<ChartTooltip stagger={90} extraColumns={extraTooltipFields} format={monthAndDayFormat} />} allowEscapeViewBox={{ x: true, y: true }} />
                        <Legend
                            content={
                                <CustomLegend legendPayload={legendData} />
                            }
                            verticalAlign="bottom"
                            wrapperStyle={{
                                bottom: `0`,
                                left: 0,
                                alignItems: 'center',
                                width: '100%',
                            }}
                        />
                        {areas}
                        {lines}
                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea
                                yAxisId={0}
                                x1={refAreaLeft}
                                x2={refAreaRight}
                                strokeOpacity={0.3}
                            />
                        ) : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
})
