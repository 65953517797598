import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'

import { PanelStyleContext } from '../panel/PanelStyleContext'

export default ({
    filterResultsCount,
    filterResultsLoading,
    filterExpanded,
    isCollapsed,
    disclaimer
}) => {
    const { colorClass } = useContext(PanelStyleContext)
    return (
        <div
            className={`field is-horizontal is-grouped is-grouped-centered 
            ${ filterExpanded && !isCollapsed ? 'is-visible' : 'is-hidden' }
            formResultsSummaryWrapper`}>
            <div className="control is-full">
                {!filterResultsLoading ? (
                    !isNaN(filterResultsCount) ? (
                        <span className="label is-small">
                            This search will produce {` `}
                            <span className="is-loading">
                                {filterResultsCount} results.
                            </span>                         
                        </span>
                      
                    ) : (
                        <span className="label is-small">
                            Apply a filter to refine results.
                        </span>
                    )
                ) : (
                    <span className="filterResultsLoadingWrapper">
                        <IconContext.Provider
                            value={{
                                className: 'fa-pulse ' + colorClass,
                                size: '1rem',
                                style: {
                                    marginRight: 10,
                                },
                            }}
                        >
                            <FaSpinner />
                        </IconContext.Provider>
                        Loading search results count.
                    </span>
                )}
            </div>
            <div>
            <span className='disclaimer'>
                {disclaimer}
             </span>   
            </div>                  
        </div>
        
    )
}
