import React from 'react'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../Tooltip'

export default ({
    toggleCollapsed,
    isCollapsed
}) => {
    return (
        <div
            className={`panelHeaderIconDiv`}
            data-tip={`Collapse / Expand`}
            data-for={`displayCollapse`}
            onClick={ () => toggleCollapsed() }
        >
            <IconContext.Provider
                value={{
                    className: `panelHeaderIcon`,
                }}
            >
                {isCollapsed ? (
                    <IoIosArrowUp />
                ) : (
                    <IoIosArrowDown />
                )}
            </IconContext.Provider>
            <Tooltip id={'displayCollapse'} />
        </div>
    )
}




