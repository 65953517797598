import React from 'react'

export default ({ onClickFunction, label }) => {
    return (
        <div
            className="panelButton timeWindowOptionWrapper"
            onClick={() => onClickFunction()}
        >
            <span className="timeWindowOptionText">{label}</span>
        </div>
    )
}