import React from 'react'

import DataContextProvider from './DataContext'
import Panel from '../../../../elem/panel/Panel'
import { aggregatePanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
// import { AppStateContext } from '../AppStateContext'
import WaterLevelAggregateChart from './WaterLevelAggregateChart'
import WaterLevelAggregateHeader from './WaterLevelAggregateHeader'
import MeasurementTypeDropdown from './MeasurementTypeDropdown'

export default ({ width }) => {
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <WaterLevelAggregateHeader name={`WaterLevelAggregateChart`} />
                    <MeasurementTypeDropdown />
                    <WaterLevelAggregateChart />
                </DataContextProvider>
            </div>
        </Panel>
    )
}
