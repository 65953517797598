import React, { useContext } from 'react'

import { TableContext } from './TableContext'
import Modal from '../../../elem/Modal'

export default (props) => {
    const { confirmDeleteModalData ,setConfirmDeleteModalData } = useContext(TableContext)

    return (
        <Modal
            display={confirmDeleteModalData.display}
            confirmAction={() => props.deleteFunction(confirmDeleteModalData.uploadId)} 
            confirmText={`Delete`}
            denyAction={() => setConfirmDeleteModalData({ display: false, uploadId: null })}
            denyText={`Cancel`}
            title={'Delete Confirmation'}
        >
            Do you wish to delete Form ID {confirmDeleteModalData.uploadId}?
        </Modal>
    )
}
