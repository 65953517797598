import React from 'react'

export default ({
    selectedMeasurementType,
    toggleSelectedMeasurementType,
    measurementTypeList,
    displayMeasureTypeWindowDropdown,
    colorClass,
}) => {
    if (!displayMeasureTypeWindowDropdown) {
        return null
    }

    return (
        <div className="analyteDropdownWrapper">
            {measurementTypeList.map((option, idx) => {
                const active = selectedMeasurementType.MeasurementType === option.MeasurementType
                return (
                    <div
                        key={`timeoption-${idx}`}
                        className={`analyteOptionWrapper ${
                            active ? 'activeOption ' + colorClass : ''
                        }`}
                        onClick={() => {
                            toggleSelectedMeasurementType(option)
                        }}
                    >
                        <span className="analyteOptionText">
                            {option.MeasurementTypeDisplay}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}
