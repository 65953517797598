import React, { useContext, useMemo } from 'react'

import WellExplorer from './lists/WellExplorer'
import WaterLevelExplorer from './lists/WaterLevelExplorer'
import Map from './map/Map'
import WellDetail from './wellDetail/Detail'
import WaterLevel from './charts/waterLevel/WaterLevel'
import Hydrograph from './charts/hydrograph/Hydrograph'
import { AppStateContext } from './AppStateContext'
import DetailsMenuDataContext from './wellDetail/DataContext'
import DetailChart from './wellDetail/chart/DetailChart'
import { detailPanel as panelClassConfig } from '../../../utils/styles/panelStyles'
import Panel from '../../elem/panel/Panel'
import WaterLevelAggregate from './charts/waterLevelAggregate/WaterLevelAggregate'

export default () => {
    const { expandedMenu } = useContext(AppStateContext)
    
    const waterLevelExplorer = (width) => <WaterLevelExplorer key={`water-level-explorer`} width={width} />
    const wellExplorer = (width) => <WellExplorer key={`well-explorer`} width={width} />
    const map = (width) => <Map key={`map`} width={width} />
    const waterLevelComposedChart = (width) => <WaterLevel key={`water-level-composed-chart`} width={width} />
    const waterLevelAggregateChart = (width) => <WaterLevelAggregate key={`water-level-aggregate-chart`} width={width} />
    const detailsChartMenu = () => (
        <div className={`is-fullheight`}>
            <Panel panelClassConfig={panelClassConfig()}>
                <DetailsMenuDataContext >
                    <DetailChart />
                </DetailsMenuDataContext>
            </Panel>
        </div>
    )

    const menus = [
        { ordinal: 0, name: 'WaterLevel', Component: waterLevelExplorer, group: 'oneThirdMenus' },
        { ordinal: 1, name: 'WaterLevelComposedChart', Component: waterLevelComposedChart, group: 'oneThirdMenus' },
        { ordinal: 2, name: 'WaterLevelAggregateChart', Component: waterLevelAggregateChart, group: 'oneThirdMenus' },
        { ordinal: 0, name: 'Map', Component: map, group: `twoThirdsMenus` },
        { ordinal: 1, name: 'Well', Component: wellExplorer, group: `twoThirdsMenus` },
        { ordinal: 0, name: 'DetailsWaterLevelChart', Component: detailsChartMenu },
    ]

    const currentlyExpandedComponent = useMemo(() => { 
        const expanded = menus.find(x => x.name === expandedMenu)
        return expanded ? expanded.Component('full') : null
    } , [expandedMenu])

    const oneThirdMenus = useMemo(() => {
        const defaultOneThirdMenus = menus.filter(x => x.group === `oneThirdMenus`)
        return defaultOneThirdMenus.sort(x => x.ordinal)
    }, [expandedMenu])

    const twoThirdsMenus = useMemo(() => {
        const defaultTwoThirdsMenus = menus.filter(x => x.group === `twoThirdsMenus`)
        return defaultTwoThirdsMenus.sort(x => x.ordinal)
    }, [expandedMenu])
    
    return (
    <div className="spaWrapper">
        <WellDetail />
        <Hydrograph />
        <div className="columns spaContainer is-full is-multiline is-1 is-variable">
            {currentlyExpandedComponent 
                ? (
                    <div key={`expanded`} className="is-full-desktop is-full-tablet is-full-mobile expandedWrapper">
                        {currentlyExpandedComponent}
                    </div>
                ) : null 
            }
            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                {oneThirdMenus.map(x => x.Component('one-third'))}
                {/* <WaterLevelExplorer width={'one-third'} />
                <WaterLevel width={'one-third'} />
                <SampleExplorer width={'one-third'} />
                <Aggregate width={'one-third'} /> */}
            </div>
            <div className="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                {twoThirdsMenus.map(x => x.Component('full'))}
                {/* <Map width={'full'} />
                <WellExplorer width={'full'} /> */}
            </div>
        </div>
    </div>
)}
