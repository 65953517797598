// palette obtained from ColorBrewer: https://colorbrewer2.org/#type=qualitative&scheme=Paired&n=12
// and mixed with material design palette https://www.materialpalette.com/colors
const qualitative = [
    '#1f78b4', // blue
    '#33a02c', // green
    '#00bcd4', // cyan 500
    '#ff5722', // deep orange 500
    '#e91e63', // pink 500
    '#3f51b5', // indigo 500
    '#ab47bc', // purple 400
    // '#f44336', // red 500
    '#673ab7', // deep purple 500
    '#1b5e20', //dark green
    '#ffc107', // amber 500
    '#ff7f00', // orange
    '#aeea00', // lime green A700
    //additional colors added
    '#880e4f', //dark purple
    '#90a4ae', // blue grey 300
    '#009688', // teal 500
    '#fdbf6f', // creme
    '#cab2d6', // lavender
    '#6a3d9a', // purple
    '#ffab40', // med orange
    '#f48fb1', // light pink
    // '#b71c1c', //dark red
    '#b2df8a', // light green
    '#a6cee3', // light blue
    '#b15928', // light brown
]

const sequential5 = [
    '#08519c', // blue    
    '#3182bd', // blue
    '#6baed6', // blue
    '#bdd7e7', // blue
    '#006d2c', // green
    '#31a354', // green
    '#74c476', // green
    '#bae4b3', // green
    '#a63603', // orange / red
    '#e6550d', // orange / red
    '#fd8d3c', // orange / red
    '#fdbe85', // orange / red
    '#54278f', // purple
    '#756bb1', // purple
    '#9e9ac8', // purple
    '#cbc9e2', // purple
    '#252525', // grey / black
    '#636363', // grey / black
    '#969696', // grey / black
    '#cccccc', // grey / black
]

// palette obtained from ColorBrewer: https://colorbrewer2.org/#type=sequential&scheme=Reds&n=6
const heatMap = [
    '#fee5d9',
    '#fcbba1',
    '#fc9272',
    '#fb6a4a',
    '#de2d26',
    '#a50f15',
]
export default qualitative
export { qualitative, heatMap, sequential5 }
