import React, { createRef, forwardRef, useContext, useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import { FaInfoCircle } from 'react-icons/fa'

import Tooltip from '../Tooltip'
import SelectStyles from './SelectStyles'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { PanelStyleContext } from '../panel/PanelStyleContext'

const SelectAllComponent = forwardRef((props, ref) => {
    if (props.allowSelectAll) {
        const allOption = {
            label: "Select All",
            value: "*"
        }
        return (
            <ReactSelect
              ref={ref}
              {...props}
              isDisabled = {props.disabled}
              options={[allOption, ...props.options]}
              onChange={selected => {
                if (
                  selected !== null &&
                  selected.length > 0 &&
                  selected[selected.length - 1].value === allOption.value
                ) {
                  return props.onChange(props.options)
                }
                return props.onChange(selected)
              }}
            />
          );
    }
    return <ReactSelect 
        ref={ref}
        {...props}
    />
})

const mapValuesToOptions = (values, fieldAccessorMap) => {
    const options = values.map(v => {
        return {
            label: v[fieldAccessorMap.label],
            value: v[fieldAccessorMap.value],
            dependentOnValue: v[fieldAccessorMap.dependentOnValue]
        }
    })
    return options
}

const getInitialValues = (name, formName, params, options) => {
    const filterName = `${name}Value`
    const initialFilterValues = params[formName] && params[formName][filterName]
        ? decodeURIComponent(params[formName][filterName]).split(',')
        : []

    const initialValues = initialFilterValues.map(x =>
        options.find(y => y.value === x.toString() || y.value === x || y.value === parseInt(x))
        )
    return initialValues
}

const MultiSelect = ({
    name,
    fieldName,
    comparisonOperators,
    values,
    helper,
    control,
    fieldAccessorMap,
    formWidth,
    formName,
    register,
    registerParams,
    dependentOn,
    dependentOnDisabled
}) => {
    const { params } = useContext(ParameterContext)
    const { selectDropDownCaratClass, tooltipClass } = useContext(PanelStyleContext)
    let disabledCondition = useMemo(() => dependentOn !== null && dependentOn !== undefined && dependentOnDisabled === true && control.getValues()[`${dependentOn}Multi`] === null, [dependentOn, control, dependentOnDisabled])
    const [disabled, setDisabled] = useState(disabledCondition)
    const [options, setOptions] = useState(mapValuesToOptions(values, fieldAccessorMap))
    const [dependentValues, setDependentValues] = useState([])
    const initialValues = getInitialValues(name, formName, params, options)
    const dependantControlValues = useMemo(() => control.getValues()[`${dependentOn}Multi`], [control, dependentOn])
    const currentControlValues = useMemo(() => control.getValues()[`${name}Multi`], [control])
    const controlName = `${name}Multi`
    const selectRef = createRef()

    useEffect(() => {
        if (disabled) {
            selectRef.current.state.menuIsOpen = false
        }
    }, [selectRef.current, disabled])

    useEffect(() => {
        let newOptions = [...options]
        if(dependentOn !== null && dependentOn !== undefined) {
            // if (!dependentOnDisabled) { //Special exception for cases like "IF Dependent Control has value then disabled else enabled" see for a practical example https://coordinatesolutions.atlassian.net/browse/WNS-90 
            //     if(currentControlValues && dependantControlValues && control && controlName) 
            //         control.setValue(controlName,null)
            //     setDisabled(!!dependantControlValues) // has no value = false, has value = true            
            //     setOptions(newOptions)
            //     return
            // }
            if(dependantControlValues) {
                const newDependentValues = dependantControlValues.map(a=> +a.value).sort()
                const oldDependentValues = dependentValues.map(a=> +a.value).sort()
                const isEqual = (newDependentValues.length === oldDependentValues.length) && (newDependentValues.every(val => oldDependentValues.includes(val)))
                if(!isEqual) {
                    setDependentValues(dependantControlValues);
                    const selectedValues = dependantControlValues.map(a=> +a.value)
                    const filteredValues = [...values.filter(a=> selectedValues.includes(Number(a.dependentOnValue)))]
                    newOptions = mapValuesToOptions(filteredValues, fieldAccessorMap)
                    setDisabled(false)
                    if(currentControlValues && control && currentControlValues.length > 0) {
                        const newValues = currentControlValues.filter(a=> dependantControlValues.map(b=> +b.value).includes(+a.dependentOnValue))
                        control.setValue(controlName,newValues);
                    }
                    
                }
            } else if(dependentOnDisabled) {
                newOptions = []
                setDependentValues([])
                if(currentControlValues && control && controlName) {
                    control.setValue(controlName,null)
                }  
                setDisabled(!!dependentOnDisabled)
            } else {
                newOptions = mapValuesToOptions(values, fieldAccessorMap)
            }
            setOptions(newOptions);           
        }
    }, [dependantControlValues, dependentOn, dependentOnDisabled, control, controlName, disabled])

    return (
        <div className={`column ${formWidth === 'full' ? 'is-one-third' : 'is-full'} no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
            <input 
                className="is-hidden" 
                name={`${name}Operator`} 
                value={comparisonOperators ? comparisonOperators[0] : '='}
                ref={register(registerParams)}
                readOnly={true}               
            />
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label">{fieldName}</label>
                    </div>
                    <div className="field-body">
                        <div className={`field ${helper ? 'has-addons' : ''}`}>
                            {helper && (
                                <div 
                                data-tip={`${helper}`} 
                                data-for={name}
                                className="control formHelper"
                                >
                                    <span
                                        
                                        className="button is-static is-small"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} className={tooltipClass} /> 
                                    </span>
                                </div>
                            )}
                            <div className={`control is-expanded ${selectDropDownCaratClass}`}>
                                <Controller
                                    as={
                                        <SelectAllComponent
                                            ref={selectRef}
                                            isMulti
                                            allowSelectAll={true}
                                            isClearable
                                            className="select is-small is-multiple is-fullwidth reactSelect"
                                            classNamePrefix="reactSelect"
                                            menuPlacement="auto"
                                            options={options}
                                            styles={SelectStyles}
                                            placeholder={"Select Multiple..."}
                                            closeMenuOnSelect={false}
                                            disabled={disabled}
                                        />
                                    }
                                    name={`${name}Multi`}
                                    control={control}
                                    onChange={([selected]) => {
                                        const value = selected && selected.length ? selected : null 
                                        // React Select return object instead of value for selection
                                        return { value }
                                    }}
                                    defaultValue={
                                        initialValues.length
                                            ? initialValues
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiSelect
